(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/specific-dialogs/assets/javascripts/timelimit-dialog.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/specific-dialogs/assets/javascripts/timelimit-dialog.js');
"use strict";

const {
  navigate
} = svs.utils.url;
const {
  urlMapping
} = svs.core;
const timelimitDialog = {
  title: 'Du har nått din tidsgräns',
  text: 'När du har nått din tidsgräns kan du inte betala för spel på Sport och Casino.',
  buttons: [{
    text: 'STÄNG',
    classes: {
      inverted: true
    }
  }, {
    text: 'TILL MINA GRÄNSER',
    classes: {
      focus: true
    },
    callback: () => {
      navigate(urlMapping.get('myProfileLimits'));
    }
  }],
  icon: 'time-limit'
};

setGlobal('svs.components.marketplaceShared.specificDialogs.dialogs.timelimitDialog', timelimitDialog);

 })(window);